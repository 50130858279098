import type { NextPage, NextPageContext } from 'next'

import { Layout } from '../components/organisms/Layout'
import { ErrorTemplate } from '../components/templates/ErrorTemplate'
import { RydePassWebErrorType } from '../utils/Errors'

// production時(next buildの成果物を使っている時)のエラー表示に使われる
// https://nextjs.org/docs/advanced-features/custom-error-page

type Props = {
  statusCode: number
}
const ErrorPage: NextPage<Props> = ({ statusCode }) => {
  const renderErrorTemplate = (code: number) => {
    switch (code) {
      case 404:
        return <ErrorTemplate type={RydePassWebErrorType.NotFound} />
      default:
        return <ErrorTemplate />
    }
  }
  return <Layout>{renderErrorTemplate(statusCode)}</Layout>
}

ErrorPage.getInitialProps = async ({ err }: NextPageContext) => {
  // statusCodeを算出する。
  // - resが存在する時はSSRであり、res.statusCodeをそのまま利用すれば良い。
  // - resがない場合はCSRである。
  //   - err.statusCodeがあればそれをそのまま利用する
  //   - 意図しない例外が起きてerrがここに渡ってくる場合、単なるErrorオブジェクトが入っていてstatusCodeプロパティがない。errがある時点でISEなので500にする
  // See Also: https://nextjs.org/docs/advanced-features/custom-error-page
  // const statusCode = res ? res.statusCode : (err ? (err.statusCode ?? 500) : 404)
  let statusCode: number
  if (err) {
    statusCode = err.statusCode ?? 500
  } else {
    statusCode = 404
  }
  return { statusCode }
}

export default ErrorPage
